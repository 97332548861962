<script>
import store from '@/infrastructure/store'

export default {
  mounted() {
    store.commit('setPageTitle', 'Play api errors')
  },

  data() {
    return {
      useCases: [
        {
          name: 'Authorization requirement',
          errorPayload: {
            id: '1',
            message: 'Request failed with status code 403',
            response: {
              data: {
                errors: [{ code: 'Forbidden', message: 'A dependent user cannot perform this action' }],
              },
            },
          },
        },
        {
          name: 'Plain BadRequest',
          errorPayload: {
            id: '1',
            message: 'Request failed with status code 403',
            response: {
              data: {
                type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
                title: 'One or more validation errors occurred.',
                status: 400,
                traceId: '00-6697a92a2e5f0947b106cd6ed63387f9-b100894814f4c84d-00',
                errors: {
                  Name: ['The Name field is required.'],
                },
              },
            },
          },
        },

        {
          name: 'Fake BadRequestErrorsResponse',
          errorTrigger: () => store.dispatch('admin/fetchFakeError', { errorType: 'BadRequestErrorsResponse' }),
        },
        {
          name: 'Fake ProblemBadRequest',
          errorTrigger: () => store.dispatch('admin/fetchFakeError', { errorType: 'ProblemBadRequest' }),
        },
      ],
    }
  },

  methods: {
    executeUseCase(useCase) {
      if (useCase.errorPayload) {
        store.commit('addError', useCase.errorPayload)
      } else if (useCase.errorTrigger) {
        useCase.errorTrigger()
      } else {
        alert('Invalid use case, no errorPayload or errorTrigger')
      }
    },
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-title>Add fake global api errors</v-card-title>
      <v-card-text>
        <v-btn v-for="(useCase, i) in useCases" :key="useCase.name" block @click="executeUseCase(useCase)" :class="i > 0 ? ['mt-5'] : []">
          {{ useCase.name }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
