<script>
import MyItemCard from '@/features/items/components/MyItemCard'

export default {
  components: {
    MyItemCard,
  },

  props: {
    items: { type: Array, required: true },
    itemCssClass: { type: String },
    highlightedItemId: { type: String },
    highlightedItemScrollOffset: { type: Number },
  },

  data() {
    return {
      busyShakingItemId: undefined,
    }
  },

  watch: {
    highlightedItemId: {
      handler(to) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.busyShakingItemId = to
            setTimeout(() => {
              this.busyShakingItemId = undefined
            }, 2000)
          })
        }, 500)
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <transition-group v-if="items.length > 0" name="item-cards-transition">
    <MyItemCard
      v-for="item in items"
      :key="item.id"
      :item-id="item.id"
      :name="item.name"
      :description="item.description"
      :rating="item.rating"
      :price="item.price"
      :shop="item.shop"
      :links="item.links"
      :archived="item.archived"
      :image-ids="item.imageIds"
      :is-highlighted="highlightedItemId === item.id"
      :highlighted-item-scroll-offset="highlightedItemScrollOffset"
      :class="{ [itemCssClass]: true, shake: busyShakingItemId === item.id }"
      class="item-cards-transition-row"
    />
  </transition-group>
</template>
