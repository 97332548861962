<script>
import store from '@/infrastructure/store'

export default {
  data() {
    return {
      shopUrl: 'https://www.takealot.com/logitech-mx-anywhere-3-mouse-graphite/PLID72054741',
      shop: 'Takealot',

      itemDetails: undefined,

      isDetecting: false,
    }
  },

  methods: {
    async autoDetect() {
      this.isDetecting = true
      this.itemDetails = undefined
      try {
        this.itemDetails = await store.dispatch('items/autoDetectItemDetailsFromShopUrl', {
          shopUrl: this.shopUrl,
          shop: this.shop,
        })
      } finally {
        this.isDetecting = false
      }
    },
  },
}
</script>

<template>
  <div>
    <v-card :loading="isDetecting">
      <v-card-title>Load item details from shop url</v-card-title>

      <v-card-text>
        <div>
          <v-text-field label="Shop product url" v-model="shopUrl" />
        </div>
        <v-btn block :disabled="isDetecting" :loading="isDetecting" @click="autoDetect">Auto detect</v-btn>
      </v-card-text>

      <v-card v-if="itemDetails" class="mx-3">
        <div class="d-flex align-center">
          <div>
            <v-card-title class="pb-0">{{ itemDetails.name }}</v-card-title>
            <v-card-text class="pt-0">
              <div class="success--text">
                <v-icon small color="success">mdi-cart-outline</v-icon>
                {{ itemDetails.shop }}
              </div>

              <div>R {{ itemDetails.price }}</div>
            </v-card-text>
          </div>
          <div>
            <v-img v-if="itemDetails.imageUrl" :src="itemDetails.imageUrl" width="70" />
          </div>
        </div>
      </v-card>

      <div class="pt-3"></div>
    </v-card>
  </div>
</template>
