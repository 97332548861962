<script>
import store from '@/infrastructure/store'
import { reactiveFormatRelativeTimeAgo } from '@/infrastructure/dates/dateFormatting'
import { toRefs } from '@vue/composition-api'
import ItemInfoSection from '@/features/items/components/ItemInfoSection'
import ItemCardRating from '@/features/items/components/ItemCardRating'
import MenuWithActions from '@/infrastructure/components/MenuWithActions'
import ItemNameField from '@/features/items/components/fields/ItemNameField'
import ItemPriceField from '@/features/items/components/fields/ItemPriceField'
import ItemShopField from '@/features/items/components/fields/ItemShopField'
import ItemImageField from '@/features/items/components/fields/ItemImageField'
import useApiImageUploading from '@/features/images/composables/imageFileComposables'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'

export default {
  components: {
    MenuWithActions,
    ItemInfoSection,
    ItemCardRating,
    ItemNameField,
    ItemPriceField,
    ItemShopField,
    ItemImageField,
  },

  props: {
    itemId: { type: String, required: true },
    name: { type: String, required: true },
    description: { type: String },
    rating: { type: Number, default: () => 0 },
    price: { type: Number },
    shop: { type: String },
    links: { type: Array, default: () => [] },
    archived: { type: String },
    imageIds: { type: Array },
    isHighlighted: { type: Boolean },
    highlightedItemScrollOffset: { type: Number },
  },

  setup(props) {
    const archivedRelativeTimeAgo = reactiveFormatRelativeTimeAgo(toRefs(props).archived)

    const { isBusy: isArchiving, execute: executeArchive } = useAsyncLoading({
      actionFactory: () => store.dispatch('items/archiveMyItem', { itemId: props.itemId }),
    })

    const { isBusy: isUnarchiving, execute: executeUnarchive } = useAsyncLoading({
      actionFactory: () => store.dispatch('items/unarchiveMyItem', { itemId: props.itemId }),
    })

    const saveName = async ({ newName }) => {
      await store.dispatch('items/updateMyItemName', { itemId: props.itemId, newName: newName })
    }

    const savePrice = async ({ newPrice }) => {
      await store.dispatch('items/updateMyItemPrice', { itemId: props.itemId, newPrice: newPrice })
    }

    const saveRating = async ({ newRating }) => {
      await store.dispatch('items/updateMyItemRating', { itemId: props.itemId, newRating: newRating })
    }

    const saveShop = async ({ newShop }) => {
      await store.dispatch('items/updateMyItemShop', { itemId: props.itemId, newShop: newShop })
    }

    const saveDescription = async ({ newDescription }) => {
      await store.dispatch('items/updateMyItemDescription', { itemId: props.itemId, newDescription: newDescription })
    }

    const saveLinks = async ({ newLinks }) => {
      await store.dispatch('items/updateMyItemLinks', { itemId: props.itemId, newLinks: newLinks })
    }

    const { progressPercent: imageUploadPercentage, error: imageUploadError, executeUpload: executeImageUpload } = useApiImageUploading()

    return {
      archivedRelativeTimeAgo,

      executeArchive,
      isArchiving,

      executeUnarchive,
      isUnarchiving,

      saveName,
      savePrice,
      saveRating,
      saveShop,
      saveDescription,
      saveLinks,

      imageUploadPercentage,
      imageUploadError,
      executeImageUpload,
    }
  },

  data() {
    return {
      isAddedByAuthUser: true, // currently my items are always added by auth user

      displayMode: null,

      isShowingActions: false,
    }
  },

  computed: {
    isEditMode() {
      return this.displayMode === 'edit'
    },

    firstImageId() {
      return this.imageIds && this.imageIds.length > 0 ? this.imageIds[0] : undefined
    },

    cardListeners() {
      if (this.displayMode) {
        return {}
      } else {
        return { click: () => this.setDisplayMode('info') }
      }
    },

    cardMenuActions() {
      const actions = []

      if (!this.isEditMode) {
        actions.push({
          key: 'edit',
          actionFunc: () => this.setDisplayMode('edit'),
          icon: 'mdi-square-edit-outline',
          title: 'Edit',
        })
      }

      if (!this.archived) {
        actions.push({
          key: 'archive',
          actionFunc: () => this.executeArchive(),
          isActionBusy: this.isArchiving,
          icon: 'mdi-trash-can-outline',
          title: 'Archive',
        })
      } else {
        actions.push({
          key: 'unarchive',
          actionFunc: () => this.executeUnarchive(),
          isActionBusy: this.isUnarchiving,
          icon: 'mdi-restore',
          title: 'Unarchive',
        })
      }

      return actions
    },
  },

  watch: {
    isHighlighted: {
      handler(to) {
        if (to) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.$vuetify.goTo(this.$refs.cardElement, {
                appOffset: true,
                offset: this.highlightedItemScrollOffset || 0,
              })
            })
          }, 50)
        }
      },
      immediate: true,
    },
  },

  methods: {
    setDisplayMode(section) {
      if (this.displayMode === section) {
        this.displayMode = null
      } else {
        this.displayMode = section
      }
    },

    async replaceItemMainImage({ imageId }) {
      await store.dispatch('items/replaceMainImageOfMyItem', { itemId: this.itemId, newImageId: imageId })
    },
    async deleteItemMainImage() {
      await store.dispatch('items/deleteMainImageOfMyItem', { itemId: this.itemId })
    },
  },
}
</script>

<template>
  <v-card v-on="cardListeners" ref="cardElement">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-text>
          <div class="d-flex align-center subtitle-1">
            <ItemNameField :is-edit-mode="isEditMode" v-model="name" :save="saveName" />
          </div>

          <div :class="{ 'my-5': isEditMode }">
            <ItemCardRating :is-edit-mode="isEditMode" v-model="rating" :save="saveRating" />
          </div>

          <div class="pt-3"></div>

          <div class="d-flex align-center">
            <ItemPriceField :is-edit-mode="isEditMode" v-model="price" :save="savePrice" />
          </div>

          <div class="pt-3"></div>

          <div>
            <ItemShopField :is-edit-mode="isEditMode" v-model="shop" :save="saveShop" />
          </div>
        </v-card-text>
      </div>

      <ItemImageField
        :is-edit-mode="isEditMode"
        :value="firstImageId"
        :item-id="itemId"
        :is-my-item="true"
        :image-upload-percentage="imageUploadPercentage"
        :upload-error="imageUploadError"
        :execute-upload="executeImageUpload"
        :replace-item-image="replaceItemMainImage"
        :remove-item-image="deleteItemMainImage"
      />
    </div>

    <v-expand-transition>
      <div v-if="archived">
        <v-card-text>
          <div class="d-flex align-center flex-wrap">
            <v-spacer />

            <v-chip v-if="archived" dark class="mt-3">
              <span>
                <v-icon>mdi-trash-can-outline</v-icon>
                {{ archivedRelativeTimeAgo }}
              </span>
            </v-chip>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>

    <v-expand-transition>
      <div v-if="displayMode === 'info' || isEditMode">
        <ItemInfoSection :is-edit-mode="isEditMode" :description="description" :links="links" :save-description="saveDescription" :save-links="saveLinks" />

        <v-card-actions v-if="isAddedByAuthUser" @click.stop>
          <v-btn text color="secondary" class="text-uppercase" @click="displayMode = null">Close</v-btn>

          <v-spacer />

          <MenuWithActions :card-menu-actions="cardMenuActions" />
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>
