<script>
import playgroundChildRoutes from '@/playground/playgroundChildRoutes'

export default {
  data() {
    return {
      routes: playgroundChildRoutes.filter((r) => r.path !== ''),
    }
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="mx-auto"><h3>Playground</h3></div>
      </v-card-title>

      <v-card-text class="text-center">
        <template v-for="(route, i) in routes">
          <span v-if="i > 0" :key="`x-${route.name}`" class="mx-3">|</span>
          <router-link
            :key="route.name"
            :to="{ name: route.name }"
            v-text="route.meta && route.meta.title ? route.meta.title : route.name"
            class="child-route"
            exact-active-class="active"
          />
        </template>
      </v-card-text>
    </v-card>

    <div class="pt-3"></div>

    <transition name="child-view-transition" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
.child-route {
  text-decoration: none;
  text-transform: capitalize;
}

.child-route.active {
  font-weight: bold;
}

.child-view-transition-enter-active,
.child-view-transition-leave-to {
  transition: all 0.15s ease;
}

.child-view-transition-enter,
.child-view-transition-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
