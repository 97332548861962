<script>
import NotificationCard from '@/features/notifications/components/NotificationCard'

let tmpId = 1
export default {
  components: {
    NotificationCard,
  },

  data() {
    return {
      notifications: [
        this.createNewDummyNotification({
          type: 'SecretSantaEvents',
          defaultTitleTemplate: 'You are invited to {EventName}',
          defaultBodyTemplate: 'You are invited to {EventName} (organized by {ActorName})\nYou are buying a gift for {ReceiverUserName}\nThe budget is R 300',
          templateValues: '{"EventName":"Secret Santa 2021","ActorName":"Francois Hill","BudgetAmount":"300","ReceiverUserName":"Fhill Work"}',
          extraData: '{"SecretSantaEventId":"61653c34845478d457b7ff53","ReceiverUserId":"google-oauth2|105229246404624416194","ReceiverUserName":"Fhill Work"}',
        }),

        this.createNewDummyNotification({
          type: 'SecretSantaEvents',
          defaultTitleTemplate: 'You are invited to {EventName}',
          defaultBodyTemplate: 'You are invited to {EventName} (organized by {ActorName})\nYou are buying a gift for {ReceiverUserName}\nThe budget is R 300',
          templateValues: '{"EventName":"Secret Santa 2021","ActorName":"Francois Hill","BudgetAmount":"300","ReceiverUserName":"Fhill Work"}',
          extraData: '{"SecretSantaEventId":"61653c34845478d457b7ff53","ReceiverUserId":"google-oauth2|105229246404624416194","ReceiverUserName":"Fhill Work"}',
          recipientDependentName: 'Dependent 1',
        }),

        this.createNewDummyNotification({
          type: 'NewFriendRequest',
          defaultTitleTemplate: '{ActorName} sent you a friend request',
          defaultBodyTemplate: '{ActorName} sent you a friend request',
          templateValues: '{"ActorName":"Fhill Work"}',
          extraData: '{"FriendRequestId":"dummy-id"}',
        }),

        this.createNewDummyNotification({
          type: 'ItemArchived',
          defaultTitleTemplate: '{ActorName} archived {ItemNameWithHearts} of {ItemOwnerName}',
          defaultBodyTemplate: '{ActorName} archived {ItemNameWithHearts} of {ItemOwnerName}',
          templateValues: '{"ActorName":"Francois Hill","ItemNameWithHearts":"Item 2 ❤❤","ItemOwnerName":"Franman Hill"}',
          extraData: '{"ItemId":"dummy-id","ItemOwnerId":"dummy-id"}',
        }),

        this.createNewDummyNotification({
          type: 'ItemClaimed',
          defaultTitleTemplate: '{ActorName} claimed {ItemNameWithHearts} of {ItemOwnerName}',
          defaultBodyTemplate: '{ActorName} claimed {ItemNameWithHearts} of {ItemOwnerName}',
          templateValues: '{"ActorName":"Francois Hill","ItemNameWithHearts":"Item 2b ❤❤❤❤","ItemOwnerName":"Franman Hill"}',
          extraData: '{"ItemId":"dummy-id","ItemOwnerId":"dummy-id"}',
        }),

        this.createNewDummyNotification({
          type: 'ItemContributed',
          defaultTitleTemplate: '{ActorName} contributed towards {ItemNameWithHearts} of {ItemOwnerName}',
          defaultBodyTemplate:
            '{ActorName} contributed towards {ItemNameWithHearts} of {ItemOwnerName} (contribution: R{Amount}, sum: R{TotalContributions}/R{ItemPrice})',
          templateValues:
            '{"ActorName":"Francois Hill","Amount":"18","TotalContributions":"112","ItemPrice":"112","ItemNameWithHearts":"Item 2b ❤❤❤❤","ItemOwnerName":"Franman Hill"}',
          extraData: '{"ItemId":"dummy-id","ItemOwnerId":"dummy-id"}',
        }),

        this.createNewDummyNotification({
          type: 'ItemContributionRemoved',
          defaultTitleTemplate: '{ActorName} removed their contribution towards {ItemNameWithHearts} of {ItemOwnerName}',
          defaultBodyTemplate:
            '{ActorName} removed their contribution towards {ItemNameWithHearts} of {ItemOwnerName} (total remaining contributions: R{TotalContributions}/R{ItemPrice})',
          templateValues:
            '{"ActorName":"Fhill Work","TotalContributions":"0","ItemPrice":"112","ItemNameWithHearts":"Item 2b ❤❤❤❤","ItemOwnerName":"Franman Hill"}',
          extraData: '{"ItemId":"dummy-id","ItemOwnerId":"dummy-id"}',
        }),

        this.createNewDummyNotification({
          type: 'ItemUnarchived',
          defaultTitleTemplate: '{ActorName} un-archived {ItemNameWithHearts} of {ItemOwnerName}',
          defaultBodyTemplate: '{ActorName} un-archived {ItemNameWithHearts} of {ItemOwnerName}',
          templateValues: '{"ActorName":"Francois Hill","ItemNameWithHearts":"Item 2 ❤❤","ItemOwnerName":"Franman Hill"}',
          extraData: '{"ItemId":"dummy-id","ItemOwnerId":"dummy-id"}',
        }),

        this.createNewDummyNotification({
          type: 'ItemUnclaimed',
          defaultTitleTemplate: '{ActorName} un-claimed {ItemNameWithHearts} of {ItemOwnerName}',
          defaultBodyTemplate: '{ActorName} un-claimed {ItemNameWithHearts} of {ItemOwnerName}',
          templateValues: '{"ActorName":"Fhill Work","ItemNameWithHearts":"Item 2b ❤❤❤❤","ItemOwnerName":"Franman Hill"}',
          extraData: '{"ItemId":"dummy-id","ItemOwnerId":"dummy-id"}',
        }),
      ],
    }
  },

  methods: {
    createNewDummyNotification(fields) {
      const dummyId = tmpId++
      return Object.assign(
        {},
        {
          notificationId: `dummy-notification-id-${dummyId}`,
          eventId: `dummy-event-id-${dummyId}`,
          senderId: 'google-oauth2|105229246404624416194',
          recipientId: 'google-oauth2|111454751750373519745',
          recipientDependentId: null,
          recipientDependentName: null,
          typeId: `dummy-type-id-${dummyId}`,
          created: '2021-10-04T02:33:39Z',
        },
        fields || {}
      )
    },
  },
}
</script>

<template>
  <div>
    <NotificationCard
      v-for="notification in notifications"
      :key="`${notification.notificationId}-unread`"
      :notification-id="notification.notificationId"
      :type="notification.type"
      :type-id="notification.typeId"
      :recipient-dependent-id="notification.recipientDependentId"
      :recipient-dependent-name="notification.recipientDependentName"
      :default-title-template="notification.defaultTitleTemplate"
      :default-body-template="notification.defaultBodyTemplate"
      :template-values="notification.templateValues"
      :extra-data="notification.extraData"
      :created="notification.created"
      class="mb-3"
    />

    <NotificationCard
      v-for="notification in notifications"
      :key="`${notification.notificationId}-read`"
      :notification-id="notification.notificationId"
      :type="notification.type"
      :type-id="notification.typeId"
      :recipient-dependent-id="notification.recipientDependentId"
      :recipient-dependent-name="notification.recipientDependentName"
      :default-title-template="notification.defaultTitleTemplate"
      :default-body-template="notification.defaultBodyTemplate"
      :template-values="notification.templateValues"
      :extra-data="notification.extraData"
      :created="notification.created"
      read-on="2021-10-04T02:33:39Z"
      class="mb-3"
    />
  </div>
</template>
