<script>
import store from '@/infrastructure/store'
import MyItemsList from '@/features/items/components/MyItemsList'
import FriendItemsList from '@/features/items/components/FriendItemsList'

export default {
  components: {
    MyItemsList,
    FriendItemsList,
  },

  data() {
    return {
      groupedItems: [
        {
          groupName: 'Normal',
          items: [
            {
              id: 'id-3',
              name: 'Fitbit charge 4',
              description: 'This helps me keep track of my activeness',
              rating: 5,
              price: 200,
              shop: 'Shop 2',
              links: ['https://www.takealot.com/fitbit-charge-4-activity-tracker-black/PLID68732658'],
              imageIds: ['616b5ca97f20c0ed7225ba46'],
              archived: null,
              ownerId: 'random-owner-id',
              addedById: store.getters['auth/authUserId'],
              addedByName: 'Play user',
              claimedBy: null,
              contributions: {},
            },
          ],
        },
        {
          groupName: 'Archived',
          items: [
            {
              id: 'id-3',
              name: 'Fitbit charge 4',
              description: 'This helps me keep track of my activeness',
              rating: 5,
              price: 200,
              shop: 'Shop 2',
              links: ['https://www.takealot.com/fitbit-charge-4-activity-tracker-black/PLID68732658'],
              imageIds: ['616b5ca97f20c0ed7225ba46'],
              archived: '2021-10-04T02:33:39Z',
              ownerId: 'random-owner-id',
              addedById: store.getters['auth/authUserId'],
              addedByName: 'Play user',
              claimedBy: null,
              contributions: {},
            },
          ],
        },
        {
          groupName: 'Claimed',
          items: [
            {
              id: 'id-3',
              name: 'Fitbit charge 4',
              description: 'This helps me keep track of my activeness',
              rating: 5,
              price: 200,
              shop: 'Shop 2',
              links: ['https://www.takealot.com/fitbit-charge-4-activity-tracker-black/PLID68732658'],
              imageIds: ['616b5ca97f20c0ed7225ba46'],
              archived: '2021-10-04T02:33:39Z',
              ownerId: 'random-owner-id',
              addedById: store.getters['auth/authUserId'],
              addedByName: 'Play user',
              claimedBy: { claimerId: store.getters['auth/authUserId'], claimerName: 'Myself' },
              contributions: {},
            },
          ],
        },
      ],
    }
  },

  computed: {
    authUserId: () => store.getters['auth/authUserId'],
  },
}
</script>

<template>
  <div>
    <div v-for="group in groupedItems" :key="group.groupName">
      <v-card class="text-center">{{ group.groupName }}</v-card>
      <div class="pt-3"></div>

      <MyItemsList :items="group.items" item-css-class="mb-3" />
      <FriendItemsList :auth-user-id="authUserId" :items="group.items" item-css-class="mb-3" />
    </div>
  </div>
</template>
