<script>
import FriendPickerDialog from '@/features/friends/components/FriendPickerDialog'
import store from '@/infrastructure/store'

export default {
  components: {
    FriendPickerDialog,
  },

  data() {
    return {
      showAddFriendsDialog: false,
    }
  },

  methods: {
    onFriendPicked(friend) {
      this.showAddFriendsDialog = false
      store.commit('addToast', { message: `You chose ${friend.friendFullName}`, color: 'success' })
    },
    onDialogCanceled() {
      this.showAddFriendsDialog = false
    },
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-text>
        <div>
          <v-btn block outlined color="success" @click="showAddFriendsDialog = true"> Add friend item</v-btn>
        </div>
      </v-card-text>
    </v-card>

    <FriendPickerDialog v-if="showAddFriendsDialog" @picked="onFriendPicked" @canceled="onDialogCanceled" />
  </div>
</template>
