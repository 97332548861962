<script>
import ApplicationBusyLoadingCard from '@/infrastructure/auth/views/ApplicationBusyLoadingCard'

export default {
  components: { ApplicationBusyLoadingCard },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-title>Initial loading</v-card-title>

      <div class="pa-3">
        <ApplicationBusyLoadingCard auth-busy-loading />
      </div>
    </v-card>

    <div class="pt-3"></div>

    <v-card>
      <v-card-title>Loading when auth failed</v-card-title>

      <div class="pa-3">
        <ApplicationBusyLoadingCard
          auth-busy-loading
          auth-taking-long
          auth-full-error="We were unable to get you connected, please check your internet connection"
        />
      </div>
    </v-card>

    <div class="pt-3"></div>

    <v-card>
      <v-card-title>Loading when auth takes long</v-card-title>

      <div class="pa-3">
        <ApplicationBusyLoadingCard auth-busy-loading auth-taking-long />
      </div>
    </v-card>
  </div>
</template>
