<script>
import MyItemsList from '@/features/items/components/MyItemsList'

export default {
  components: {
    MyItemsList,
  },

  data() {
    return {
      items: [
        {
          id: 'id-1',
          name: 'Fooseball table',
          description: 'This would be really, really fun to play with all my friends',
          rating: 2,
          price: 300,
          shop: 'Shop 3',
          links: ['https://www.takealot.com/table-top-foosball-table/PLID40639643', 'https://dummy.link.com/my-random-product/id/123'],
          imageIds: ['616a9795a675667b87ac3b01'],
          archived: null,
        },
        {
          id: 'id-2',
          name: 'Fitbit charge 4',
          description: 'This helps me keep track of my activeness',
          rating: 5,
          price: 200,
          shop: 'Shop 2',
          links: ['https://www.takealot.com/fitbit-charge-4-activity-tracker-black/PLID68732658'],
          imageIds: [],
          archived: null,
        },
        {
          id: 'id-3',
          name: 'Fitbit charge 4',
          description: 'This helps me keep track of my activeness',
          rating: 5,
          price: 200,
          shop: 'Shop 2',
          links: ['https://www.takealot.com/fitbit-charge-4-activity-tracker-black/PLID68732658'],
          imageIds: ['616b5ca97f20c0ed7225ba46'],
          archived: null,
        },

        {
          id: 'id-4',
          name: 'Fitbit charge 4',
          description: 'This helps me keep track of my activeness',
          rating: 5,
          price: 200,
          shop: 'Shop 2',
          links: ['https://www.takealot.com/fitbit-charge-4-activity-tracker-black/PLID68732658'],
          imageIds: ['616b5ca97f20c0ed7225ba46'],
          archived: null,
        },

        {
          id: 'id-5',
          name: 'Duuuuude duuuuuuuuuuude duuuuuuuuuude what a cool item',
          description: 'This helps me keep track of my activeness',
          rating: 5,
          price: 200,
          shop: 'Shop 2',
          links: ['https://www.takealot.com/fitbit-charge-4-activity-tracker-black/PLID68732658'],
          imageIds: ['616b5ca97f20c0ed7225ba46'],
          archived: null,
        },
      ],
    }
  },
}
</script>

<template>
  <div>
    <MyItemsList :items="items" item-css-class="mb-3" />
  </div>
</template>
