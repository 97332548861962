<script>
import store from '@/infrastructure/store'
import ItemImageEditor from '@/features/images/components/ItemImageEditor'

export default {
  components: { ItemImageEditor },

  data() {
    return {
      imageId: 11,
      imageWidth: 160,
      imageUploadPercentage: 30,
      uploadError: undefined,
    }
  },

  computed: {
    imageIdString() {
      return String(this.imageId)
    },

    thumbnailFullUrl() {
      return this.imageId ? `https://picsum.photos/id/${this.imageId}/${this.imageWidth}/${this.imageWidth}` : undefined
    },
  },

  mounted() {
    store.commit('setPageTitle', 'Play image editor')
  },

  methods: {
    executeUpload() {
      console.debug('executeUpload')
      return { imageId: Number(this.imageId) + 1 }
    },

    replaceItemImage({ imageId }) {
      console.debug('replaceItemImage', { imageId })
      return new Promise((resolve) => {
        setTimeout(() => {
          this.imageId = Number(imageId)
          resolve()
        }, 1500)
      })
    },

    removeItemImage({ imageId }) {
      this.imageId = undefined
      console.debug('removeItemImage', { imageId })
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-text>
      <div class="text-center">These are random images from https://picsum.photos</div>

      <div class="pt-5"></div>

      <ItemImageEditor
        :image-id="imageIdString"
        :image-width="imageWidth"
        :image-upload-percentage="imageUploadPercentage"
        :upload-error="uploadError"
        :execute-upload="executeUpload"
        :remove-item-image="removeItemImage"
        :replace-item-image="replaceItemImage"
        :thumbnail-full-url="thumbnailFullUrl"
      />
    </v-card-text>
  </v-card>
</template>
