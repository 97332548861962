<script>
import store from '@/infrastructure/store'
import { onMounted, ref, watch } from '@vue/composition-api'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import { useRouterManager } from '@/infrastructure/router/composable/routerComposables'

export default {
  setup(props, { emit }) {
    const dialogVisible = ref(true)
    const { isBusy: isLoading, execute: loadFriends } = useAsyncLoading({ actionFactory: () => store.dispatch('friends/fetchOnlyAcceptedFriendRequests') })
    const { syncDialogOpenBooleanToQuery } = useRouterManager()

    onMounted(loadFriends)

    syncDialogOpenBooleanToQuery({
      queryKey: 'friend-picker-dialog-visible',
      closeDialogFunc: () => {
        dialogVisible.value = false
      },
    })

    const onPickFriend = ({ friend }) => {
      emit('picked', friend)
    }

    watch(dialogVisible, (to) => {
      if (!to) {
        emit('canceled')
      }
    })

    return {
      dialogVisible,
      isLoading,
      onPickFriend,
    }
  },

  computed: {
    acceptedFriends: () => store.getters['friends/acceptedFriendRequests'],
  },
}
</script>

<template>
  <v-dialog v-model="dialogVisible" width="400px" scrollable>
    <v-card>
      <slot name="header">
        <v-card-title>
          <div class="mx-auto">Select a friend</div>
        </v-card-title>
      </slot>

      <v-card-text style="height: 60vh">
        <v-list>
          <div v-if="isLoading && acceptedFriends.length === 0">
            <v-skeleton-loader type="list-item" />
            <v-skeleton-loader type="list-item" />
            <v-skeleton-loader type="list-item" />
          </div>
          <v-list-item v-else-if="acceptedFriends.length === 0">
            <v-list-item-content class="text-center grey--text">
              <v-list-item-title>No friends yet</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="friend in acceptedFriends" :key="friend.friendUserId" @click="onPickFriend({ friend: friend })">
            <v-list-item-avatar size="24">
              <v-img v-if="friend.friendPicture" :alt="`${friend.friendFullName} avatar`" :src="friend.friendPicture"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ friend.friendFullName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="green darken-1" text @click="dialogVisible = false"> Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
